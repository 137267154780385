import React, { Component } from 'react';

//import Admin from './components/Admin.js';
import Home from './components/Home.js';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img className="gif" src="https://media.giphy.com/media/JrHZdeASHNnwTJHgEr/giphy.gif"/>
          <div className="App-title">
          <h1 className="h1">JON SPASSOV’S NFL 2020 FOOTBALL POOL</h1><h4>Non-Official Scoreboard</h4>
          </div>
        </header>
        <div className='main'>
          <Home />
        </div>
      </div>
    );
  }
}

export default App;
