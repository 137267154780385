import React from 'react';
import ReactTable from 'react-table';
import Dropdown from 'react-dropdown';
import Checkbox from '@material/react-checkbox';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import firebase from 'firebase';

import 'react-table/react-table.css'
import 'react-dropdown/style.css';
import "@material/react-checkbox/dist/checkbox.css";
import './Home.css';

class Home extends React.Component {
    constructor() {
      super();
      this.getWinners = this.getWinners.bind(this);
      this.getWinnersPotential = this.getWinnersPotential.bind(this);
      this.onGameOptionChange = this.onGameOptionChange.bind(this);
      this.populateTable = this.populateTable.bind(this);
      this.updateData = this.updateData.bind(this);

      this.gameOptions = [
        {
          value: 'completed',
          label: 'Completed Games'
        },
        {
          value: 'all',
          label: 'Started Games'
        }
      ];

      this.state = {
        mustDisplayBB: true,
        mustDisplayInProgress: true,
        currentWeek: '',
        loaded: false,
        games: [],
        players: [],
        scores: [],
        winners: [],
        progresses: [],
        potWinners: [],
        gameOption: this.gameOptions[0]
      };
  
      var config = {
        apiKey: "AIzaSyBlEj1HNxgWDCgHWfaxCJDBH_S49rm3B9c",
        authDomain: "mayfield-3f785.firebaseapp.com",
        projectId: "mayfield-3f785",
        databaseURL: "https://mayfield-3f785.firebaseio.com/",
      };
      firebase.initializeApp(config);
    }

    componentDidMount() {
      this.updateData();
    }
    
    async fetchScores() {
        return fetch(`https://cors-anywhere.herokuapp.com/https://www.si.com/.api/si/scoreboard?league=nfl&week=1,${this.state.currentWeek}`, { headers: { 'Origin': 'www.si.com'} })
            .then(response => response.json())
            .then(result => {
                console.log(result.status);
                const scores = [];
                const progresses = [];
                const isSuccess = result.status === 'success';
                if (isSuccess) {
                    const data = result.data;
                    const dates = data.dates;
                    dates.forEach(date => {
                        const fixtures = date.fixtures;
                        fixtures.forEach(fixture => {
                            const status = fixture.status.name;
                            if (status === 'Final') {
                                const teams = fixture.teams;
                                const score = {};
                                teams.forEach(team => {
                                    let abbr = team.abbreviation.toUpperCase();
                                    if (abbr === 'PIT') {
                                        abbr = 'PGH';
                                    }
                                    score[abbr] = team.score;
                                })
                                scores.push(score);
                            } else if (status === 'In-Progress') {
                                const teams = fixture.teams;
                                const progress = {};
                                teams.forEach(team => {
                                    let abbr = team.abbreviation.toUpperCase();
                                    if (abbr === 'PIT') {
                                        abbr = 'PGH';
                                    }
                                    progress[abbr] = team.score;
                                })
                                progresses.push(progress);
                            }
                        })
                    })
                    this.setState({scores});
                    this.setState({progresses});
                }
            });
    }

    getColor(winners, potWinners, favorite, underdog) {
        const isWinnerIncludeFavorite = winners.includes(favorite);
        const isWinnerIncludeUnderdog = winners.includes(underdog);
        if (isWinnerIncludeFavorite || isWinnerIncludeUnderdog) {
            if (isWinnerIncludeFavorite) {
                return '#77dd77';
            } else {
                return '#ff6666'
            }
        } else if (potWinners.includes(favorite)) {
            return 'yellow';
        }
        return 'white';
    }

    getWinners() {
        const games = this.state.games;
        const scores = this.state.scores;
        const winners = [];
        games.forEach(game => {
            const favorite = game.favorite;
            const underdog = game.underdog;
            const spread = game.spread;
            scores.forEach(score => {
                if (score.hasOwnProperty(favorite) && score.hasOwnProperty(underdog)) {
                    const favoriteScore = score[favorite];
                    const underdogScore = score[underdog];
                    if (favoriteScore - spread > underdogScore) {
                        winners.push(favorite);
                    } else {
                        winners.push(underdog);
                    }
                }
            })
        })
        this.setState({winners});
    }

    getWinnersPotential() {
        const games = this.state.games;
        const progresses = this.state.progresses;
        const potWinners = [];
        games.forEach(game => {
            const favorite = game.favorite;
            const underdog = game.underdog;
            const spread = game.spread;
            progresses.forEach(progress => {
                if (progress.hasOwnProperty(favorite) && progress.hasOwnProperty(underdog)) {
                    const favoriteScore = progress[favorite];
                    const underdogScore = progress[underdog];
                    if (favoriteScore - spread > underdogScore) {
                        potWinners.push(favorite);
                    } else {
                        potWinners.push(underdog);
                    }
                }
            })
        })
        this.setState({potWinners});
    }

    onGameOptionChange(value) {
      this.setState({gameOption: value});
    }

    populateTable() {
        const games = this.state.games;
        const players = this.state.players;
        const loaded = this.state.loaded;
        const winners = this.state.winners;
        const potWinners = this.state.potWinners;
        const allScores = this.state.scores;
        const allProgresses = this.state.progresses;
        const all = {};
        allScores.forEach(score => {
            for(const key in score) {
                all[key] = score[key];
            }
        })
        allProgresses.forEach(score => {
            for(const key in score) {
                all[key] = score[key];
            }
        })
        const columns = [];
        if (loaded) {
            columns.push(
                {
                    Header: '',
                    columns: [{
                        Header: <div>Favorite <br/> Underdog <br/> Spread</div>,
                        accessor: 'name',
                        minWidth: 150
                    }]
                }
            )
            for (let i = 0; i < games.length; i++) {
                const game = games[i];
                const favorite = game.favorite;
                const underdog = game.underdog;
                const spread = game.spread;
                const subheader = (<div>
                    <div style={{
                        'backgroundImage': `linear-gradient(-100deg, rgba(255, 255, 255, 0), ${this.getColor(winners, potWinners, favorite, underdog)} 85%, rgba(255, 255, 255, 0))`
                        }}>
                        {favorite} - {all.hasOwnProperty(favorite) ? all[favorite] : 0}
                    </div>
                    <div style={{
                        'backgroundImage': `linear-gradient(-100deg, rgba(255, 255, 255, 0), ${this.getColor(winners, potWinners, underdog, favorite)} 85%, rgba(255, 255, 255, 0))`
                        }}>
                        {underdog} - {all.hasOwnProperty(underdog) ? all[underdog] : 0}
                    </div>
                    {spread}
                </div>)
                columns.push({
                    Header: i + 1,
                    columns: [{
                        Header: subheader,
                        accessor: `week${i + 1}`,
                        maxWidth: 80
                    }]
                })
            }
            columns.push(
                {
                    Header: 'Points',
                    columns: [{
                        Header: 'Weekly',
                        accessor: `weekly-${this.state.mustDisplayInProgress ? 'all': 'completed'}`
                    },{
                      Header: 'Total',
                      accessor: `total-${this.state.mustDisplayInProgress ? 'all': 'completed'}`
                  }]
                }
            )
            
            const rows = [];
            players.forEach(player => {
                const picks = player.picks;
                const bestBets = player.bestBets;
                const weeklyPts = `weekly-completed`;
                const totalPts = `total-completed`;
                const row = {
                    name: player.name
                };
                let weeklyPoints = 0;
                let weeklyPotPoints = 0;
                for (let i = 0; i < picks.length; i++) {
                    const pick = picks[i];
                    if (winners.includes(pick)) {
                        weeklyPoints++;
                        weeklyPotPoints++;
                    }
                    if (potWinners.includes(pick)) {
                        weeklyPotPoints++;
                    }
                    const displayBB = bestBets && bestBets.includes(`${i + 1}`) && this.state.mustDisplayBB;
                    row[`week${i + 1}`] = (<div className={`${displayBB ? 'highlight-bb': ''}`}>
                        {pick}
                    </div>);
                }
                row[weeklyPts] = weeklyPoints;
                row['weekly-all'] = weeklyPotPoints;
                row[totalPts] = +player.totalPoints + +weeklyPoints;
                row['total-all'] = +player.totalPoints + +weeklyPotPoints;
                rows.push(row);
            });
            
            return (
                <ReactTable
                data={rows}
                columns={columns}
                defaultPageSize={this.state.players.length}
                showPagination={false}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: `weekly-${this.state.mustDisplayInProgress ? 'all': 'completed'}`,
                    desc: true
                  }
                ]}
              />
            );
        }
    }

    async updateData() {
        const snapshot = await firebase.database().ref('/').once('value');
        const result = snapshot.val();
        const currentWeek = result.currentWeek;
        const games = result.games;
        const players = result.players;
        if (games && players && games.length > 0 && players.length > 0) {
            this.setState({
                currentWeek,
                loaded: true,
                games: games.slice(1),
                players: players.slice(1)
            })
        }
        await this.fetchScores();
        this.getWinners();
        this.getWinnersPotential();
    }

    render() {
      return (
        <div className='container'>
          <div className='datatable-header'>
            <h2 className='datatable-title'>{`Week ${this.state.currentWeek}`}</h2>
            <div className='dropdowns'>
              <Checkbox
                nativeControlId='my-checkbox'
                checked={this.state.mustDisplayBB}
                onChange={(e) => this.setState({mustDisplayBB: e.target.checked})
                }
              />
              <label htmlFor='my-checkbox'>Show Best Bets</label>
            <Checkbox
                nativeControlId='showInProgress'
                checked={this.state.mustDisplayInProgress}
                onChange={(e) => this.setState({mustDisplayInProgress: e.target.checked})
                }
              />
              <label htmlFor='my-checkbox'>Include In-Progress Games</label>
              <Tooltip className='refresh-btn' title="Refresh">
                <IconButton onClick={this.updateData}>
                    <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className='datatable'>
            {this.populateTable()}
          </div>
        </div>
      );
    }
  }
  

export default Home;
